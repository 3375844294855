let initialLocaleCode = 'pt-br',
    Calendar = FullCalendar.Calendar,
    calendarEl = document.getElementById('calendar'),
    calendar = new Calendar(calendarEl, {
        headerToolbar: {
            // left: 'title,prev,next today',
            // center: 'title',
            // right: 'dayGridMonth,listMonth',
            right: 'dayGridMonth,listMonth today next',
        },
        initialView: 'listMonth',
        themeSystem: 'bootstrap',
        locale: initialLocaleCode,
        editable: false,
        droppable: false,
        weekends: false,
        allDaySlot: false,
        showNonCurrentDates: false, // https://fullcalendar.io/docs/showNonCurrentDates
        height: 420,
        contentHeight: 320,
        // defaultDate: new Date(),
        // initialDate: new Date(),
        noEventsContent: 'Não há nada para ser mostrado!!!',
        eventClick: function (info) {
            var eventObj = info.event,
                dateStart = moment(eventObj.start).format("YYYY-MM-DDTHH:mm");
            if (!eventObj.id) {
                if ($(info.el).hasClass('selected')) {
                    $(info.el).removeClass('selected');
                    $("#agendamento [name='data']").val(null);
                } else {
                    $(info.el).closest('table').find('.selected').removeClass('selected');
                    $(info.el).addClass('selected');
                    $("#agendamento [name='data']").val(dateStart);
                }
            }
        },
        events: '',
        eventSourceSuccess: function (content, xhr) {
            return content.data;
        }
    });

// calendar.removeAllEvents();
calendar.render();

$('[name="service"]').change(function () {
    $('[name="responsible"]').val(null).trigger('change');
    $('[name="responsible"]').prop('disabled', false);

    $(this).closest('.services').removeClass('col-md-12');
    $(this).closest('.services').addClass('col-md-6');

    calendar.removeAllEvents();
});

$('[name="responsible"]').change(function () {
    $('[name="data"]').val(null).trigger('change');
    removeEventSource(calendar);

    if ($(this)?.val()) {
        // https://stackoverflow.com/questions/65117487/fullcalendar-io-5-addeventsource-not-work-from-external-function
        calendar.addEventSource({
            url: 'api/scheduling/',
            extraParams: function () {
                let data = {};
                if ($('[name="service"]').val()) {
                    data.service = $('[name="service"]').val();
                }

                if ($('[name="responsible"]').val()) {
                    data.responsible = $('[name="responsible"]').val();
                }

                return data;
            },
            eventRender: function (event, element) {
                // console.log(event, element);
            },
        });
    }
});

function removeEventSource(calendar) {
    calendar.removeAllEvents();
    // var eventSources = calendar.getEventSources(),
    //     len = eventSources.length;

    // for (var i = 0; i < len; i++) {
    //     eventSources[i].remove();
    // }

    // https://tutorialmeta.com/question/fullcalendar-refetch-eventsource
    calendar.getEventSources().forEach(eventSource => {
        eventSource.remove()
    });
}
